import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import enVeeValidationLocale from "@/locales/enVeeValidation";
import neVeeValidationLocale from "@/locales/neVeeValidation";
import VeeValidate from "vee-validate";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});

export default i18n;

/** Localization of vee-validate */
Vue.use(VeeValidate, {
  i18nRootKey: "validations",
  i18n,
  dictionary: {
    en: enVeeValidationLocale,
    ne: neVeeValidationLocale
  }
});
