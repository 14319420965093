














import { Vue, Component } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import { LoadStore } from "@/store/modules";

@Component({
  components: {}
})
export default class App extends Vue {
  totalNetworkCreated = 0;
  totalNetworkFinished = 0;
  showMessageForNetworkError = true;
  loading: boolean = false;

  async created() {
    LoadStore.showLoading();
    eventHub.$on("before-request", this.beforeRequest);
    eventHub.$on("after-response", this.afterResponse);

    // Hides loader if not hidden after some time
    return new Promise(resolve =>
      setTimeout(resolve => {
        LoadStore.hideLoading();
      }, 1500)
    );
  }

  get isLoading() {
    return LoadStore.loading;
  }

  get percentage() {
    if (this.totalNetworkCreated === 0) {
      return 0;
    }
    return (this.totalNetworkFinished / this.totalNetworkCreated) * 100;
  }

  beforeRequest() {
    this.$Progress.start();
    this.totalNetworkCreated = this.totalNetworkCreated + 1;
    if (this.totalNetworkCreated === 1) {
      this.showMessageForNetworkError = true;
    }
  }

  afterResponse() {
    this.totalNetworkFinished = this.totalNetworkFinished + 1;
    if (this.totalNetworkFinished >= this.totalNetworkCreated) {
      this.totalNetworkCreated = 0;
      this.totalNetworkFinished = 0;
      this.$Progress.finish();
    }
  }
}
